import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import * as contactStyles from "../components/contact.module.css"

const ContactPage = () => (
    <Layout>
        <Seo title='Get In Touch' />
        <div className='content-wrapper'>
            <h1>
                Get In Touch
            </h1>
            <form
                className={contactStyles.contactForm}
                method="POST"
                action="/thanks"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="contact"
            >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <input type="text" name="name" id="name" placeholder="Name" />
                <input type="email" name="email" id="email" placeholder="Email" />
                <input type="text" name="subject" id="subject" placeholder="Subject" />
                <textarea name="message" id="message" rows={5} placeholder="Message" />
                <button className='link-button' type="submit">Send</button>
                <input className="link-button" type="reset" value="Clear" />
            </form>

        </div>
    </Layout>
)


export default ContactPage